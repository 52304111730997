export enum AnalyticsEvent {
  TaskOpenedInNotion = "Task Opened in Notion",
  TaskConversationOpened = "Task Conversation Opened",
  TimesheetSubmitted = "Timesheet Submitted",
  TimesheetEdited = "Timesheet Edited",
  TimesheetDeleted = "Timesheet Deleted",
  TranslationDone = "Translation Done",
  MissiveContactsFetched = "Missive Contacts Fetched",
  HubspotContactsFetched = "Hubspot Contacts Fetched",
  HubspotContactCreated = "Hubspot Contact Created",
  HubspotContactUpdated = "Hubspot Contact Updated",
  HubspotContactDeleted = "Hubspot Contact Deleted",
  HubspotTaskCreated = "Hubspot Task Created",
  HubspotTaskUpdated = "Hubspot Task Updated",
  HubspotTaskDeleted = "Hubspot Task Deleted",
  NotionDataFetched = "Notion Data Fetched",
  NotionCommentAdded = "Notion Comment Added",
  NotionProjectUpdated = "Notion Project Updated",
  SummaryGenerated = "Summary Generated",
  SearchCompleted = "Search Completed",
  SearchContactSelected = "Search Contact Selected",
  SearchNotionOpened = "Search Notion Opened",
  SearchHubspotOpened = "Search Hubspot Opened",
  BulletinSaved = "Bulletin Saved",
  BulletinRun = "Bulletin Run",
  StopwatchStarted = "Stopwatch Started",
  StopwatchPaused = "Stopwatch Paused",
  StopwatchStopped = "Stopwatch Stopped",
  StopwatchToTimesheet = "Stopwatch To Timesheet",
}
